import React from 'react'

function Aboutus() {
  return (
<div>
  {/* home 2 start */}
  <section className="secondary_home mt-4">
    <div className="Header_homepage">
      <h1>Welcome to <span>QR Genie</span></h1>
    </div>
    {/* heading section */}
    <section className="secondary_home mt-5">
      <div className="container">
        <div className="row g-0"> {/* g-0 removes the gap between columns */}
          {/* Left side: content/paragraph with box shadow, centered in the middle of the image */}
          <div className="col-lg-6 d-flex align-items-center justify-content-center h-100 mt-4">
            <div className="left-content w-100 p-4 box-shadow">
              <p>
                Your personal safety assistant for your vehicle. Imagine a scenario where your vehicle is in trouble,
                whether it's a minor mishap, a forgotten light left on, or an open window. Anyone passing by can easily
                scan the QR code on your vehicle and alert you instantly, but with a crucial feature – the caller’s
                identity remains anonymous. The message or call is rerouted through a virtual number, ensuring privacy
                at all times.
              </p>
              <p>
                QR Genie is a one-time purchase product that offers lifetime usage. It's designed to offer peace of mind
                knowing that you’ll always be reachable when your vehicle needs attention. Available for purchase online
                or through our affiliate partners, QR Genie comes with two QR stickers, enabling seamless tracking and
                registration of your vehicle. Once you've received your stickers, simply register and map the QR codes
                to your details to start using it right away.
              </p>
            </div>
          </div>
          {/* Right side: image, with 'rectangle_2' positioned top-right and bottom-right */}
          <div className="col-lg-6 d-flex align-items-center justify-content-center h-100 position-relative">
            <div className="right-image w-100 position-relative">
              <img src="assests/image/Home/Home_banner_2.png" alt="QR Genie" className="img-fluid right-main-img" />
              {/* Rectangle positioned top-right */}
              <img src="assests/image/Rectangle_3.png" alt="Rectangle" className="position-absolute rectangle-top" />
              {/* Rectangle positioned bottom-right */}
              <img src="assests/image/Rectangle_3.png" alt="Rectangle" className="position-absolute rectangle-bottom" />
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* home 2 end */}
    {/* Advertise  start*/}
    <div className="container adv_text mb-4">
      <div className="row">
        <div className="col-12 col-md-4 text-container">
          <img src="assests/image/text_img/text_1.svg" alt="One time purchase" className="img-fluid mx-auto d-block" />
        </div>
        <div className="col-12 col-md-4 text-container">
          <img src="assests/image/text_img/text_2.svg" alt="Caller privacy" className="img-fluid mx-auto d-block" />
        </div>
        <div className="col-12 col-md-4 text-container">
          <img src="assests/image/text_img/text_4.svg" alt="Reach emergency co" className="img-fluid mx-auto d-block" />
        </div>
      </div>
    </div>
    {/* Advertise end */}
  </section></div>

  )
}

export default Aboutus