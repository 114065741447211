import React from 'react'
import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
<div>
  <nav className="navbar sticky-top navbar-expand-lg navbar-light">
  <div className="container">
      <NavLink className="navbar-brand" to="/">
        <img src="/assests/image/new_logo_qr.png" alt="Brand Logo" />
      </NavLink>
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsFurni" aria-controls="navbarsFurni" aria-expanded="false" aria-label="Toggle navigation">
        <span />
        <span />
        <span />
      </button>
      <div className="collapse navbar-collapse " id="navbarsFurni">
        <ul className="navbar-nav mr-2 w-100 justify-content-end ms-auto mb-2 mb-md-0">
          <li className="nav-item">
            <NavLink className="nav-link" to="/aboutus">About</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/productdetail">Product</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/howitwork">How it works</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contactus">Contact</NavLink>
          </li>
        </ul>
        <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
          <li>
          <NavLink to="/userlogin">
            <button className="Login_btn">Login</button>
            </NavLink></li>
        </ul>
      </div>
    </div>
  </nav>
</div>


  )
}

export default Navbar