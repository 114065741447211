import React from "react";

function Testimonial() {
  return (
    <div>
      {/* Testimonials Section */}
      <section id="testimonials" className="testimonials section-gap">
        <div className="Header_homepage p-4 ">
          <h1 style={{ color: "#FFFFFF" }}>
            Clients<span style={{ color: "#FFFFFF" }}> Testimonial</span>
          </h1>
        </div>
        <div className="testimonial">
          <div className="container">
            <div className="testimonial__inner">
              <div className="testimonial-slider">
                {/* first slide */}
                <div className="testimonial-slide">
                  <div className="testimonial_box posi-rl">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img
                            src="assests/image/testimonial/testimonials-1.jpg"
                            alt="profile"
                          />
                        </div>
                        <div className="testimonial_box-name">
                          <h4>Kristin Watson</h4>
                        </div>
                        <div className="testimonial_box-job">
                          <p>XYZ Company</p>
                        </div>
                        <div
                          className="stars"
                          style={{ color: "#ffc107", margin: "0 1px" }}
                        >
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                        </div>
                        <i className="fa fa-quote-left" aria-hidden="true" />
                        <div className="testimonial_box-text">
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Omnis enim aperiam impedit voluptatum velit
                            adipisci molestiae harum, nemo fugiat nesciunt et
                            excepturi necessitatibus quibusdam porro soluta
                            illum nulla voluptas atque.
                          </p>
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <img
                      className="ab-quote"
                      src="assests/image/arrow-shadow.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* second slide */}
                <div className="testimonial-slide">
                  <div className="testimonial_box posi-rl">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img
                            src="assests/image/testimonial/testimonials-2.jpg"
                            alt="profile"
                          />
                        </div>
                        <div className="testimonial_box-name">
                          <h4>John Doe</h4>
                        </div>
                        <div className="testimonial_box-job">
                          <p>XYZ Company</p>
                        </div>
                        <div
                          className="stars"
                          style={{ color: "#ffc107", margin: "0 1px" }}
                        >
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                        </div>
                        <i className="fa fa-quote-left" aria-hidden="true" />
                        <div className="testimonial_box-text">
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Omnis enim aperiam impedit voluptatum velit
                            adipisci molestiae harum, nemo fugiat nesciunt et
                            excepturi necessitatibus quibusdam porro soluta
                            illum nulla voluptas atque.
                          </p>
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <img
                      className="ab-quote"
                      src="assests/image/arrow-shadow.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* third slide */}
                <div className="testimonial-slide">
                  <div className="testimonial_box posi-rl">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img
                            src="assests/image/testimonial/testimonials-3.jpg"
                            alt="profile"
                          />
                        </div>
                        <div className="testimonial_box-name">
                          <h4>John Doe</h4>
                        </div>
                        <div className="testimonial_box-job">
                          <p>ABC Company</p>
                        </div>
                        <div
                          className="stars"
                          style={{ color: "#ffc107", margin: "0 1px" }}
                        >
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                        </div>
                        <i className="fa fa-quote-left" aria-hidden="true" />
                        <div className="testimonial_box-text">
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Omnis enim aperiam impedit voluptatum velit
                            adipisci molestiae harum, nemo fugiat nesciunt et
                            excepturi necessitatibus quibusdam porro soluta
                            illum nulla voluptas atque.
                          </p>
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <img
                      className="ab-quote"
                      src="assests/image/arrow-shadow.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* fourth slide */}
                <div className="testimonial-slide">
                  <div className="testimonial_box posi-rl">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img
                            src="assests/image/testimonial/testimonials-4.jpg"
                            alt="profile"
                          />
                        </div>
                        <div className="testimonial_box-name">
                          <h4>John Doe</h4>
                        </div>
                        <div className="testimonial_box-job">
                          <p>PQR Company</p>
                        </div>
                        <div
                          className="stars"
                          style={{ color: "#ffc107", margin: "0 1px" }}
                        >
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                        </div>
                        <i className="fa fa-quote-left" aria-hidden="true" />
                        <div className="testimonial_box-text">
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Omnis enim aperiam impedit voluptatum velit
                            adipisci molestiae harum, nemo fugiat nesciunt et
                            excepturi necessitatibus quibusdam porro soluta
                            illum nulla voluptas atque.
                          </p>
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <img
                      className="ab-quote"
                      src="assests/image/arrow-shadow.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* fifth slide */}
                <div className="testimonial-slide">
                  <div className="testimonial_box posi-rl">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img
                            src="assests/image/testimonial/testimonials-5.jpg"
                            alt="profile"
                          />
                        </div>
                        <div className="testimonial_box-name">
                          <h4>John Doe</h4>
                        </div>
                        <div className="testimonial_box-job">
                          <p>ABC company</p>
                        </div>
                        <div
                          className="stars"
                          style={{ color: "#ffc107", margin: "0 1px" }}
                        >
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                        </div>
                        <i className="fa fa-quote-left" aria-hidden="true" />
                        <div className="testimonial_box-text">
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Omnis enim aperiam impedit voluptatum velit
                            adipisci molestiae harum, nemo fugiat nesciunt et
                            excepturi necessitatibus quibusdam porro soluta
                            illum nulla voluptas atque.
                          </p>
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <img
                      className="ab-quote"
                      src="assests/image/arrow-shadow.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* end */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Testimonials Section */}
    </div>
  );
}

export default Testimonial;
