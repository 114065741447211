import React from 'react'
import { useLocation,NavLink } from 'react-router-dom';

function ThankuPostpaid() {
    const location = useLocation();
    const { UserLoginID, vehicleOwnerMasterDetailId } = location.state || {};
  const orderID = location.state?.orderID;
  const message = `Your details added successfully..!`;
  return (
    <div>
    {/* <h2 className="logo_heading">QR Genie</h2> */}
    <div className="text-center mt-5 mb-4">
      <img src="assests/image/Thanku.png" alt="Company Picture" className="company-pic" />
      <p className='text-bold' style={{color:'66666',fontSize:'18px'}}>{message}</p>
      <NavLink to="/userdetail"  state={{ UserLoginID, vehicleOwnerMasterDetailId }}>
      <button className="Login_btn">Home</button>
      </NavLink>
    </div>
  </div>
  )
}

export default ThankuPostpaid