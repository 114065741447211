import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function QuickContact() {
  const [vehiclePlateNumber, setVehiclePlateNumber] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [selectedMessageId, setSelectedMessageId] = useState(null); // State to track selected message

  const quickContactImg = "assests/image/quick_final.png"; 
  const logoImg = "assests/image/new_logo_qr.png"; 
  
  // Static content with unique IDs and icons
  const staticMessages = [
    { id: 'msg_001', text: "The lights of the car is on", icon: "assests/image/quick_icons/random_icon_1.svg" },
    { id: 'msg_002', text: "The car is in no parking zone", icon: "assests/image/quick_icons/random_icon_2.svg" },
    { id: 'msg_003', text: "The car is getting towed", icon: "assests/image/quick_icons/random_icon_3.svg" },
    { id: 'msg_004', text: "There is a baby or pet in the car", icon: "assests/image/quick_icons/random_icon_4.svg" },
    { id: 'msg_005', text: "Window of the car is open", icon: "assests/image/quick_icons/random_icon_5.svg" },
  ];

  useEffect(() => {
    const storedBloodGroup = localStorage.getItem('strBloodGroup');
    const storedVehiclePlateNumber = localStorage.getItem('vehiclePlateNumber');

    if (storedBloodGroup) setBloodGroup(storedBloodGroup);
    if (storedVehiclePlateNumber) setVehiclePlateNumber(storedVehiclePlateNumber);
  }, []);

  return (
    <>
      <header className="header-container d-flex justify-content-between align-items-center mb-2">
        <img src={logoImg} alt="Logo" className="logo-img" style={{ height: '60px', paddingLeft: '17px', width: '175px' }} />
        <img src={quickContactImg} alt="Quick Contact" className="quick-img" />
      </header>
      <div className="container mt-4 p-3" style={{ paddingLeft: '0', paddingRight: '0' }}>
        <div className="box-1 mb-4 border rounded shadow">
          <h5 className="bg-dark text-white p-3 mb-3" style={{ borderRadius: '5px 5px 0px 0px' }}>Contact Vehicle Owner</h5>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column" style={{ padding: '0px 20px 20px 30px'}}>
              <label htmlFor="vehicle-plate">Vehicle License Plate Number: <span className="text-danger">*</span></label>
              <span style={{ fontSize: '20px', color: '#EF4F5F' }}>{vehiclePlateNumber || 'N/A'}</span>
            </div>
            <div className="d-flex flex-column" style={{ padding: '0px 20px 20px 30px' }}>
              <label htmlFor="blood-group">Blood Group: <span className="text-danger">*</span></label>
              <div className="d-flex align-items-center">
                <img src="assests/image/Layer 2.svg" alt="Blood Picture" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                <span style={{ fontSize: '20px', color: '#EF4F5F' }}>{bloodGroup || 'N/A'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="box-2 mb-4 p-3 border rounded shadow" style={{ borderRadius: '15px' }}>
          <h6 className="mb-3">Please select a message to contact owner of the vehicle</h6>
          <div className="d-flex flex-column">
            {staticMessages.map((message) => (
              <div className="d-flex align-items-center mb-2" key={message.id}>
                <img src={message.icon} alt="Icon" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                <div className={`flex-grow-1 message-text ${selectedMessageId === message.id ? 'selected' : ''}`}
                     style={{ fontWeight: 500, color: selectedMessageId === message.id ? '#EF4F5F' : '#666666' }}>
                  {message.text}
                </div>
                <input className="form-check-input custom-radio" type="radio" name="option"
                id={`option_${message.id}`} onChange={() => setSelectedMessageId(message.id)} />
                <label htmlFor={`option_${message.id}`}></label> {/* Add a label here */}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="btn_emergency d-flex align-items-center me-2" style={{ width: '9em', color: '#292929', border: '2px solid #292929'}}>
              <i className="fas fa-envelope me-2"></i>
              Message
            </button>
            <button className="btn_emergency d-flex align-items-center" style={{ width: '9em', color: '#292929', border: '2px solid #292929' }}>
              <i className="fas fa-phone me-2"></i>
              Call
            </button>
          </div>
        </div>

        <div className="text-center mb-4">
          <div className="bg-dark text-white" style={{ borderRadius: '32px', maxWidth: '30em', margin: '0 auto', border: '2px solid #ddd', padding: '5px' }}>
            <div className="d-flex align-items-center">
              <div className="bg-white lock_icon" style={{ borderRadius: '50%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <img src="/assests/image/call-lock-icon.svg" alt="privacy icon" style={{ width: '35px', height: '35px' }} />
              </div>
              <h5 className="ms-3 mb-0" style={{ fontSize: '16px', textAlign: 'center' }}>The privacy of all callers will be protected</h5>
            </div>
          </div>
        </div>

        <div className="text-center mb-4">
          <button className="btn_emergency">
            <img src="/assests/image/emergency_img.png" alt="Emergency Icon" className="me-2" style={{ width: '24px', height: '24px' }} />
            Emergency Contact Number
          </button>
        </div>

        <div className="text-center mb-4">
          <h5>
            Require QR Genie? <NavLink to="/productdetail" style={{ color: '#EF4F5F', cursor: 'pointer', textDecoration: 'none' }}>Buy Now</NavLink>
          </h5>
        </div>
      </div>
    </>
  );
}

export default QuickContact;
