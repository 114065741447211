import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    margin: 30,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  return (
    <div>
      {/* Home start */}
      <section className="main_home">
        <div className="container">
          <div className="row align-items-center">
            {/* Left side with skewed shape */}
            <div className="col-lg-8 col-md-6 p-0 skewed-box">
              <div className="content">
                <h1 style={{ fontSize: 24, top: 0, justifyContent: "center" }}>
                  The new age contact card. <br />
                  Scan the QR and call the owner
                </h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                  <br />
                  Nullam massa mauris, euismod at tristique quis, <br />{" "}
                  ullamcorper feugiat risus.
                </p>
                <div className="button-container d-flex">
                  <NavLink to="/userlogin">
                    <button className="btn btn_main_home">Login</button>
                  </NavLink>
                  <NavLink to="/contactus">
                    <button className="btn btn_main_home">Contact</button>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* Right side with image */}
            <div className="col-lg-4 col-md-4 p-0 image-side">
              {/* The background image will appear here */}
            </div>
          </div>
        </div>
      </section>

      {/* Home end */}
      {/* home 2 start */}
      <section className="secondary_home section-gap">
        <div className="Header_homepage">
          <h1>
            Welcome to <span>QR Genie</span>
          </h1>
        </div>
        {/* heading section */}
        <section className="mt-5">
  <div className="container">
    <div className="row g-0"> {/* g-0 removes the gap between columns */}
      {/* Left side: content/paragraph with box shadow, centered in the middle of the image */}
      <div className="col-lg-6 d-flex align-items-center justify-content-center h-100 mt-4 secondary_home">
        <img src="assests/image/arrow/vertical_dot.png" alt="Vertical Dot" className="vertical-dot-img_home2"  loading="lazy" />
        <div className="left-content w-100 p-4">
          <p>
            Your personal safety assistant for your vehicle. Imagine a scenario where your vehicle is in trouble,
            whether it's a minor mishap, a forgotten light left on, or an open window. Anyone passing by can easily
            scan the QR code on your vehicle and alert you instantly, but with a crucial feature – <strong>the caller’s
            identity remains anonymous.</strong>The message or call is rerouted through a virtual number, ensuring privacy
            at all times.
          </p>
          <p>
            <strong>QR Genie</strong> is a one-time purchase product that offers lifetime usage. It's designed to offer peace of mind
            knowing that you’ll always be reachable when your vehicle needs attention. Available for purchase online
            or through our affiliate partners, QR Genie comes with two QR stickers, enabling seamless tracking and
            registration of your vehicle. Once you've received your stickers, simply register and map the QR codes
            to your details to start using it right away.
          </p>
        </div>
      </div>
      {/* Horizontal dot image positioned between the columns */}
      <img src="assests/image/arrow/Horizontal_img.png" alt="Horizontal Dot" className="position-absolute  horizontal-dot-img_home2"  loading="lazy" />
      {/* Right side: image, with 'rectangle_2' positioned top-right and bottom-right */}
      <div className="col-lg-6 d-flex align-items-center justify-content-center h-100 position-relative">
        <div className="right-image w-100 position-relative">
              <img src="assests/image/Home/Home_banner_2.png" alt="QR Genie" className="img-fluid right-main-img" />
          {/* Rectangle positioned top-right */}
          <img src="assests/image/Rectangle_3.png" alt="Rectangle" className="position-absolute rectangle-top" loading="lazy" />
          {/* Rectangle positioned bottom-right */}
          <img src="assests/image/Rectangle_3.png" alt="Rectangle" className="position-absolute rectangle-bottom"  loading="lazy" />
        </div>
      </div>
    </div>
  </div>
</section>

        {/* home 2 end */}

        <div className="container-fluid ov-x-h pt50">
          <Slider {...settings}>
            <div>
              <img
                src="assests/image/text_img/text_1.svg"
                alt="One time purchase"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div>
              <img
                src="assests/image/text_img/text_2.svg"
                alt="Caller privacy"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div>
              <img
                src="assests/image/Contact-img.png"
                alt="Reach emergency co"
                className="img-fluid mx-auto d-block"
              />
            </div>
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default Home;
